import { graphql } from 'gatsby'
import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import ContentHero from '../components/ContentHero'
import InformationCard from '../components/InformationCard'

const PortfolioPage = props => {
  const { data } = props
  return (
    <Layout>
      <Hero
        title="Für Technologieführer"
        subtitle="Wir wollen echte Werte schaffen und nachhaltig den Erfolg sichern"
        img={data.hero.childImageSharp}
      />
      <div className="container">
        <ContentHero
          title="Unsere Kompetenz"
          subtitle="Mehr als 150 Kunden aus dem Mittelstand verlassen sich heute auf unsere maßgeschneiderte Kombination aus Informationsicherheit,
          Compliance und passgenauen technischen Lösungen aus einer Hand. Das Team aus Sicherheitsexperten, spezialisierten Juristen, Cloud-Architekten und
          Ingenieuren sorgt mit regionaler Präsenz, umfassendem Know-how und pragmatischer Vorgehensweise für eine technisch optimale und gleichzeitig rechtssichere Umsetzung digitaler Vorhaben."
        />
        <div className="columns">
          <div className="column is-third">
            <InformationCard
              img={data.architecture.childImageSharp}
              title="Platform | Cloud Architecture"
              content="Cloud Native ist bei uns keine Phrase.
              Wir sorgen dafür, dass Unternehmen die digitale Transformation meistern und sich für die Zukunft optimal aufstellen.
              Von der Entwicklung von Zielarchitekturen über das Assessment von Systemen bis zur Technologieberatung - vor allem für Plattform und Cloud Architekturen - bieten wir eine umfassende Expertise. "
            />
          </div>
          <div className="column is-third">
            <InformationCard
              img={data.development.childImageSharp}
              title="Development | Engineering"
              content="Wir reden nicht nur, wir machen es auch!
              Neben der Beratung und Konzeption ist die Umsetzung von Ideen eine unserer Stärken. Wir fühlen uns wohl, wenn wir mit Kunden schnell echte Mehrwerte schaffen.
              Für uns sind Begriffe wie .NET Core, React, SCRUM, Agile und Web Development keine Fremdworte."
            />
          </div>
          <div className="column is-third">
            <InformationCard
              img={data.compliance.childImageSharp}
              title="Compliance | Datenschutz"
              content="Wir nehmen den Schutz der Daten ernst. Sie auch?

              Digitale Innovationen brauchen umfassende Rechtssicherheit von Anfang an und aus allen
              relevanten Perspektiven. Wir begleiten Ihr Vorhaben kompetent und pragmatisch und sorgen
              für umfassende Compliance zu den Vorgaben von DSGVO, BDSG und TTDSG national und im
              Zusammenspiel mit internationalen Geschäftspartnern. Gerne übernehmen wir für Ihr
              Unternehmen die Aufgabe des externen Datenschutzbeauftragen (DSB) gegenüber Aufsichtsbehörden."
            />
          </div>
        </div>
        <div className="columns">
          <div className="column is-third">
            <InformationCard
              img={data.data.childImageSharp}
              title="Data Management | Data Science"
              content="Richtige Transformers entstehen aus Daten. Nicht aus Autos.

              Von Data Management über Data Governance bis hin zur Analyse großer Datenmengen reicht unsere Expertise.
              Wir helfen ihnen, aus Daten Mehrwerte zu generieren. Mit Hilfe von intelligenten Algorithmen machen wir ihre Prozesse, Maschinen und Produkte noch intelligenter."
            />
          </div>
          <div className="column is-third">
            <InformationCard
              img={data.code.childImageSharp}
              title="DevOps | Managed Services"
              content="Wir bauen die digitale Lieferkette.

              Software lebt. Software entwickelt sich weiter. Aus diesem Grund kommt dem Thema DevOps heute eine immer wichtigere Bedeutung zu.
              Wir konzeptionieren, implementieren und betreiben für Sie entsprechende CI/CD Umgebungen.
              So sind Sie immer in der Lage schnell auf Fehler zu reagieren, neue Software - Releases auszurollen und mit wachsender Nutzerzahl elastisch zu skalieren."
            />
          </div>
          <div className="column is-third">
            <InformationCard
              img={data.process.childImageSharp}
              title="Business Process Automation"
              content="Automatisierung ist Vereinfachung.

              Manuelle Prozesse kosten Zeit und sind Quelle für Fehler.
              Wir automatisieren für Sie Prozesse und Workflows.
              Damit Sie sich auf das Wesentliche konzentrieren können: Ihr Geschäft."
            />
          </div>
        </div>
        <div className="columns">
          <div className="column is-third">
            <InformationCard
              img={data.lock.childImageSharp}
              title="Cloud | Cyber Security"
              content="Sind Sie sicher?

              Wir beraten Sie umfassend zu technischen und organisatorischen Fragen der Informationssicherheit und bei Aufbau und Steuerung eines optimierten Risikomanagements.
              Wir identifizieren Bedrohungen und Schwachstellen, führen geeignete Maßnahmen für eine erfolgreiche Abwehr ein und begegnen operativen Herausforderungen in der IT-Sicherheit
              wie Kryptographie und Zertifikatsmanagement, Identity & Access Management, Cloud-Transformation, Remote Workplace und Netzwerksicherheit."
            />
          </div>
          <div className="column is-third">
            <InformationCard
              img={data.compliance.childImageSharp}
              title="Compliance | Legal"
              content="Wir sichern Ihre Projekte auch rechtlich ab!
              Neben der Compliance bzgl. den Anforderungen des Datenschutzes und
              der Information Security bietet die Rechtsanwaltskanzlei Advokat Pro
              rechtliche Beratung, Betreuung und Vertretung bei IT- und Medienrecht,
              Lizenz- und Open Source-Recht, sowie bei Verhandlungen, Verträgen und
              Rechtsstreitigkeiten an.

              Hinweis: Rechtliche Beratung im Sinne des Rechtsdienstleistungsgesetzes
              (RDG) erfolgt ausschließlich durch die Rechtsanwaltskanzlei Advokat Pro."
            />
          </div>
          <div className="column is-third"></div>
        </div>
      </div>
    </Layout>
  )
}

PortfolioPage.propTypes = {
  data: PropTypes.shape({
    allPortfolioYaml: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default PortfolioPage

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "portfolio2.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          aspectRatio: 3.1
          transformOptions: { fit: COVER, cropFocus: CENTER }
        )
      }
    }
    architecture: file(relativePath: { eq: "architecture-color-mod.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 600, width: 700)
      }
    }
    development: file(relativePath: { eq: "development-color-mod.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 600, width: 700)
      }
    }
    compliance: file(relativePath: { eq: "compliance-color-mod.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 600, width: 700)
      }
    }
    data: file(relativePath: { eq: "data-color-mod.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 600, width: 700)
      }
    }
    code: file(relativePath: { eq: "code-color-mod.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 600, width: 700)
      }
    }
    process: file(relativePath: { eq: "process-color-mod.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 600, width: 700)
      }
    }
    lock: file(relativePath: { eq: "lock-color-mod.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 600, width: 700)
      }
    }
  }
`
